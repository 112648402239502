<template>
  <dashboard-layout>
    <div class="w-10/12 mx-auto">
      <router-link
        :to="{ name: 'list-regionals' }"
        class="bg-green-1000 text-white px-6 py-3 rounded rounded-md border border-gray-400 focus:outline-none"
      >
        <svg
          style="display: initial;"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          class="bi bi-chevron-left"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
          />
        </svg>
        voltar
      </router-link>
      <div class="p-4 flex">
        <h1 class="text-3xl">
          Nova Regional
        </h1>
      </div>
      <form-wizard
        @on-complete="onComplete"
        :start-index="0"
        color="#69CA5E"
        title=""
        subtitle=""
        ref="form"
        next-button-text="Próximo"
        back-button-text="Anterior"
        finish-button-text="Finalizar"
      >
        <tab-content title="Identificação" icon="ti-user">
          <div
            class="w-12/12 border border-green-600  rounded-lg py-3 px-5 mx-auto mt-5"
          >
            <div class="flex w-full mb-5">
              <div class="flex w-4/12 flex-col mr-5">
                <label class="mb-2" for="regional">Regional</label>
                <input
                  v-model="$v.regional.name.$model"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="regional"
                  type="text"
                  placeholder="Nome da Regional"
                  autocomplete="off"
                />
                <div v-if="$v.regional.name.$error">
                  <div class="error" v-if="!$v.regional.name.required">
                    Campo obrigatório.
                  </div>
                  <div class="error" v-else-if="!$v.regional.name.maxLength">
                    O nome da regional deve ter no máximo
                    {{ $v.regional.name.$params.maxLength.max }}
                    caracteres.
                  </div>
                </div>
              </div>

              <div class="flex w-4/12 flex-col mr-5">
                <label class="mb-2" for="telephone">Telefone fixo</label>
                <input
                  v-model="regional.telephone"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="telephone"
                  type="text"
                  placeholder="Telefone"
                  v-mask="'(##) ####-####'"
                  autocomplete="off"
                />
              </div>

              <div class="flex w-4/12 flex-col">
                <label class="mb-2" for="status">Status</label>
                <multiselect
                  id="status"
                  v-model="$v.regional.status.selected.$model"
                  track-by="label"
                  label="label"
                  select-label="Selecionar"
                  selected-label="Selecionado"
                  deselect-label="Remover"
                  :searchable="false"
                  placeholder="Selecione um tipo"
                  :options="regional.status.options"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.label }}
                  </template>
                  <span slot="noOptions">Nenhum registro encontrado.</span>
                </multiselect>
                <div v-if="$v.regional.status.selected.$error">
                  <div
                    class="error"
                    v-if="!$v.regional.status.selected.required"
                  >
                    Campo obrigatório.
                  </div>
                </div>
              </div>
            </div>

            <div class="flex w-full mb-5">
              <div class="flex mb-5 w-full flex-col">
                <label class="flex items-center mb-2" for="syndicates">
                  Sindicatos
                </label>
                <multiselect
                  id="syndicates"
                  v-model="$v.syndicates.selected.$model"
                  tag-placeholder="Adicionar cidade"
                  placeholder="Procure ou adicione uma cidade"
                  label="name"
                  track-by="name"
                  :options="syndicates.filteredOptions"
                  :multiple="true"
                  :taggable="true"
                  select-label="Selecionar"
                  deselect-label="Remover"
                  selected-label="Selecionado"
                  @tag="addSyndicate"
                  :internal-search="false"
                  @search-change="syndicatesSearchChange"
                >
                  <span slot="noOptions"
                    >Comece a pesquisar para que os sindicatos apareçam
                    aqui.</span
                  >
                </multiselect>
              </div>
            </div>
          </div>
        </tab-content>
        <tab-content title="Endereço" icon="ti-location-pin">
          <div
            class="w-12/12 border border-green-1001  rounded-lg py-3 px-5 mx-auto mt-5"
          >
            <div class="flex w-full mb-5">
              <div class="flex w-3/12 flex-col mr-5">
                <label class="mb-2" for="cep">CEP</label>
                <input
                  id="cep"
                  type="text"
                  v-model="address.cep"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  placeholder="CEP"
                  v-mask="'#####-###'"
                />
              </div>

              <div class="flex w-7/12 flex-col mr-5">
                <label class="mb-2" for="address">Endereço</label>
                <input
                  id="address"
                  type="text"
                  v-model="address.address"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  placeholder="Endereço"
                />
              </div>

              <div class="flex w-2/12 flex-col mr-5">
                <label class="mb-2" for="number">Número</label>
                <input
                  id="number"
                  type="text"
                  v-model="address.number"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  placeholder="Número"
                />
              </div>
            </div>

            <div class="flex w-full mb-5">
              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="complement">Complemento</label>
                <input
                  id="complement"
                  type="text"
                  v-model="address.complement"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  placeholder="Complemento"
                />
              </div>

              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="neighborhood">Bairro</label>
                <input
                  id="neighborhood"
                  type="text"
                  v-model="address.neighborhood"
                  class="appearance-none block w-full bg-white text-gray-700 border border-gray-600 rounded-lg py-2 px-3 mb-3 leading-tight focus:outline-none focus:bg-white"
                  placeholder="Bairro"
                />
              </div>
            </div>

            <div class="flex w-full mb-5">
              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="state">Estado</label>
                <multiselect
                  id="state"
                  v-model="address.states.selected"
                  deselect-label="Remover"
                  track-by="name"
                  label="name"
                  select-label="Selecionar"
                  selected-label="Selecionado"
                  placeholder="Selecione um estado"
                  :options="address.states.options"
                  @select="fetchCities"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.name }}
                  </template>
                  <span slot="noOptions">Nenhum registro encontrado.</span>
                </multiselect>
              </div>

              <div class="flex w-6/12 flex-col mr-5">
                <label class="mb-2" for="city">Cidade</label>
                <multiselect
                  id="city"
                  v-model="$v.address.cities.selected.$model"
                  deselect-label="Remover"
                  track-by="name"
                  label="name"
                  select-label="Selecionar"
                  selected-label="Selecionado"
                  placeholder="Selecione uma cidade"
                  :options="address.cities.options"
                >
                  <template slot="singleLabel" slot-scope="{ option }">
                    {{ option.name }}
                  </template>
                  <span slot="noOptions">Nenhum registro encontrado.</span>
                </multiselect>
                <div v-if="$v.address.cities.selected.$error">
                  <div
                    class="error"
                    v-if="!$v.address.cities.selected.required"
                  >
                    Campo obrigatório.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tab-content>
      </form-wizard>
    </div>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '@/layouts/DashboardLayout';
import { mask } from 'vue-the-mask';
import { required, maxLength } from 'vuelidate/lib/validators';
import axios from '@/utils/axios';

export default {
  name: 'CreateRegional',

  title() {
    return `${process.env.VUE_APP_NAME} | Criar Regional`;
  },

  components: {
    DashboardLayout
  },

  directives: { mask },

  data() {
    return {
      regional: {
        name: '',
        telephone: '',
        status: {
          options: [
            { label: 'Ativo', value: 1 },
            { label: 'Inativo', value: 0 }
          ],
          selected: null
        }
      },
      address: {
        cep: '',
        address: '',
        number: '',
        complement: '',
        neighborhood: '',
        country: '',
        states: {
          options: [],
          selected: null
        },
        cities: {
          options: [],
          selected: null
        }
      },

      syndicates: {
        options: [],
        filteredOptions: [],
        selected: []
      }
    };
  },

  validations: {
    regional: {
      name: {
        required,
        maxLength: maxLength(200)
      },
      status: {
        selected: {
          required
        }
      }
    },
    address: {
      cities: {
        selected: { required }
      }
    },
    syndicates: {
      selected: { required }
    }
  },

  methods: {
    onComplete() {
      if (this.$v.regional.$invalid) {
        this.$refs.form.changeTab(1, 0);

        this.$v.regional.name.$touch();
        this.$v.regional.status.selected.$touch();
        this.$v.syndicates.selected.$touch();
      } else if (this.$v.address.$invalid) {
        this.$v.address.cities.selected.$touch();
      } else {
        const regionalAttributes = {
          name: this.regional.name,
          telephone: this.regional.telephone,
          status: this.regional.status.selected.value,
          syndicates: this.syndicates.selected.map(syndicate => syndicate.id)
        };

        const addressAttributes = {
          city_id: this.address.cities.selected.id,
          cep: this.address.cep,
          address: this.address.address,
          number: this.address.number,
          complement: this.address.complement,
          neighborhood: this.address.neighborhood
        };

        axios
          .post('/api/regionais', regionalAttributes)
          .then(({ data }) => {
            this.$toast.success(data.data.message);

            axios
              .post(
                `/api/regionais/${data.data.data.id}/endereco`,
                addressAttributes
              )
              .then(({ data }) => {
                this.$toast.success(data.data.message);

                this.$router.push({ name: 'list-regionals' });
              })
              .catch(({ response }) => {
                Object.values(response.data.errors).forEach(error => {
                  this.$toast.error(...error);
                });
              });
          })
          .catch(({ response }) => {
            Object.values(response.data.errors).forEach(error => {
              this.$toast.error(...error);
            });
          });
      }
    },

    addSyndicate(syndicate) {
      const tag = {
        name: syndicate.name,
        id: syndicate.id
      };
      this.options.push(tag);
      this.value.push(tag);
    },

    async fetchSyndicates() {
      await axios.get('api/sindicatos').then(response => {
        response.data.data.forEach(syndicate => {
          this.syndicates.options.push({
            name: syndicate.name,
            id: syndicate.id
          });
        });
      });
    },

    removeDiacritics(text) {
      return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },

    normalizedContains(needle, haystack) {
      const regExp = new RegExp(this.removeDiacritics(needle), 'gi');
      return regExp.test(this.removeDiacritics(haystack));
    },

    syndicatesSearchChange(search) {
      this.syndicates.filteredOptions = search
        ? this.syndicates.options.filter(option =>
            this.normalizedContains(search, option.name)
          )
        : this.syndicates.options;
    },

    async fetchStates() {
      await axios.get('/api/estados').then(({ data }) => {
        data.data.forEach(state =>
          this.address.states.options.push({
            id: state.id,
            name: state.name
          })
        );
      });
    },

    async fetchCities(state) {
      this.address.cities.options = [];
      this.address.cities.selected = null;

      await axios.get(`/api/estados/${state.id}/cidades`).then(({ data }) => {
        data.data.forEach(city =>
          this.address.cities.options.push({
            id: city.id,
            name: city.name
          })
        );
      });
    }
  },
  created() {
    this.fetchStates();
    this.fetchSyndicates();
  }
};
</script>

<style scoped>
.error {
  color: red;
}
</style>
